
// JQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// Library
require('./bootstrap');
require('slick-slider')

import '@fancyapps/ui/dist/fancybox/fancybox.css';

// Importing FancyBox JavaScript
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm';
window.Fancybox = Fancybox;

Fancybox.bind("[data-fancybox]", {
});

$('#toggler').on('click', function() {
    let height = $('main#main').height();
    height = height - 90;
    $("html, body").animate({ scrollTop: height }, 600);
})



// function when screen width is larger than 1200px
$(window).on('load resize', function() {
    if ($(window).width() > 1200) {
        setTimeout(function() {
            $('#toggler').addClass('show');
        }, 5000);
    }
});

// on click #toggler add class show
$('#toggler').on('click', function() {
    $(this).toggleClass('show');
});